import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCXPvY8T2K2NYDgAaCw6C6U24FSkbNjsFs",
    authDomain: "projectx-7674e.firebaseapp.com",
    projectId: "projectx-7674e",
    storageBucket: "projectx-7674e.appspot.com",
    messagingSenderId: "165629340007",
    appId: "1:165629340007:web:0e1c6f9f06c878da2ae7c2",
    measurementId: "G-PK27V0LJV8"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);

export default db;
