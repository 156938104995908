import { Outlet } from "react-router-dom";
import "./styles.scss";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const HomeLayout = ({setIsVisible}: any) => {
  return (
    <div>
      <Navbar setIsVisible={setIsVisible} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default HomeLayout;
