import "./styles.scss";
import { useCallback, useEffect, useState } from "react";
import { useMailChimp } from "react-use-mailchimp-signup";
import toast from "react-hot-toast";
import validator from "validator";
import Modal from "react-bootstrap/Modal";
import { addWaitlistUser, countWaitlistUser } from "../../api";
import Checklist from "../../assets/icons/checklist.svg";
import RocketSuccess from "../../assets/images/success-rocket.png";
import RocketSuccessWebP from "../../assets/images/success-rocket.webp";
import Copy from "../../assets/icons/copy.svg";
import {
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  TwitterIcon,
  WhatsappIcon,
  FacebookIcon,
  LinkedinIcon,
} from "react-share";

const JoinWaitListModal = ({ isVisible, onClose }: any) => {
  const { error, loading, status, subscribe, message } = useMailChimp({
    action:
      "https://gmail.us14.list-manage.com/subscribe/post-json?u=74b5e2ed323935fb40a6279cb&id=e3020ea94f",
  });
  const [mailchimpStatus, setMailchimpStatus] =
    useState<string | undefined>("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [waitlistUsersCount, setWaitlistUsersCount] = useState<number>(0);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [products, setProducts] = useState<Array<string>>([]);
  const [isFirebaseSubmitting, setIsFirebaseSubmitting] =
    useState<Boolean>(false);
  const [isFormReset, setIsFormReset] = useState<Boolean>(true);

  const resetForm = () => {
    setIsEmailValid(true);
    setEmail("");
    setProducts([]);
    setBusinessName("");
    setMailchimpStatus("");
    setIsFormReset(true);
  }

  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    if (!isVisible) {
      resetForm();
    }
  }, [isVisible])

  useEffect(() => {
    setMailchimpStatus(status);
  }, [status]);

  useEffect(() => {
    countWaitlistUser(setWaitlistUsersCount);
  }, []);

  const addFirebaseWaitlistUser = useCallback(async () => {
    await addWaitlistUser({
      email,
      businessName,
      interests: products.join("+"),
    });
    setIsFirebaseSubmitting(false);
  }, [email, businessName, products]);

  useEffect(() => {
    if (mailchimpStatus === "success" && !isFormReset) {
      // toast.success("Thanks for subscribing! 🤩 ");
      addFirebaseWaitlistUser();
    }
  }, [mailchimpStatus, isFormReset, addFirebaseWaitlistUser]);

  useEffect(() => {
    if (error) {
      toast.error(`${message || "An error occurred. Please try again!"} 🙁`);
      setIsFirebaseSubmitting(false);
    }
  }, [error, message]);

  const handleUpdateProducts = (e: any) => {
    const value: string = e.target.value;
    const _products = [...products];
    const index = products.indexOf(value);

    if (index === -1) {
      _products.push(value);
    } else {
      _products.splice(index, 1);
    }

    setProducts(_products);
  };

  const copyText = (entryText: string) => {
    navigator.clipboard.writeText(entryText);
    toast.success("Copied successfully!");
  };

  const handleSubscribe = () => {
    if (validator.isEmail(email)) {
      const payload: any = {
        EMAIL: email,
        BUS_NAME: businessName,
        interest:
          products.length > 1 ? "feedback+self-service" : products.join("+"),
      };
      setIsEmailValid(true);
      setIsFirebaseSubmitting(true);
      setIsFormReset(false);
      subscribe(payload);
    } else {
      setIsEmailValid(false);
      toast.error("Oops, please enter a valid mail! 🥺");
    }
  };

  const referralLink = `https://keepevery.com`;
  const referralTitle = 'Keepevery is a Customer Success & Retention Tool Stack that helps you maintain long-lasting relationships with your customers.'

  return (
    <Modal centered show={isVisible} onHide={onClose}>
      <div className="modal-body">
        <div className="text-right">
          <button
            type="button"
            className="btn-close"
            onClick={onClose}
          ></button>
        </div>
        {(status === "success" && !isFormReset) ? (
          <div className="row justify-content-center success-pane">
            <div className="col-lg-12 text-center">
              <h3>
                Welcome on board! <img src={Checklist} alt="success" />
              </h3>
              <p>
                Thanks for taking this step with keepevery. We'd keep you
                posted.
              </p>
              <picture>
                <source data-aos="fade-in" srcSet={RocketSuccessWebP} type="image/webp" />
                <source data-aos="fade-in" srcSet={RocketSuccess} type="image/jpeg" />
                <img
                  src={RocketSuccess}
                  className="img-fluid success-img"
                  alt="success"
                />
              </picture>
              <p className="share">
                In the meantime, kindly invite your friends on your different
                social media platforms
              </p>
              <div className="socials">
                <TwitterShareButton
                  url={referralLink}
                  title={referralTitle}
                  hashtags={['keepevery', 'CustomerRetention', 'CustomerSuccess', 'SaaS']}
                  related={['keepeveryhq']}
                  className="Demo__some-network__share-button"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={referralLink}
                  title={referralTitle}
                  separator=":: "
                  className="Demo__some-network__share-button"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <FacebookShareButton
                  url={referralLink}
                  quote={referralTitle}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <LinkedinShareButton
                  url={referralLink}
                  className="Demo__some-network__share-button"
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </div>
              <div className="copy-link">
                <div className="link-text">{referralLink}</div>
                <button className="btn" onClick={() => copyText(referralLink)}>
                  <img src={Copy} alt="copy" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h3>Be the first to know when we launch. 🚀</h3>
              <p>
                Join{" "}
                <span>
                  {waitlistUsersCount > 0 &&
                    waitlistUsersCount.toLocaleString()}
                </span>{" "}
                other <b>businesses ahead of you</b>
              </p>
              <div>
                <div className="form-group">
                  <label className="features-label">
                    <span style={{ color: "crimson" }}>*</span> Email Address
                  </label>
                  <input
                    type="email"
                    className={`form-control ${
                      isEmailValid ? "" : "form-error"
                    }`}
                    onChange={(e) => setEmail(e.target.value.trim())}
                    placeholder="example: user@mail.com"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="features-label">
                    Business Name{" "}
                    <span style={{ opacity: 0.7 }}>(optional)</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setBusinessName(e.target.value.trim())}
                    placeholder="Business Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="features-label">
                    Which features are you anticipating?{" "}
                    <span style={{ opacity: 0.7 }}>(optional)</span>
                  </label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleUpdateProducts}
                      value="feedback"
                      id="flexCheckDefault1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault1"
                    >
                      Feedback Management
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleUpdateProducts}
                      value="self-service"
                      id="flexCheckDefault2"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault2"
                    >
                      Self-Service Portals
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    disabled={loading}
                    className="btn btn-primary btn-block"
                    onClick={handleSubscribe}
                  >
                    {loading || isFirebaseSubmitting
                      ? "Joining wait list..."
                      : "Join our wait list"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default JoinWaitListModal;