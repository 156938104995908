import { collection, setDoc, doc, onSnapshot, query } from "firebase/firestore"; 
import db from "../utils/firebase";


export const addWaitlistUser = async (payload: any) => {
    const {email} = payload;
    try {
      const docRef = await setDoc(doc(db, "waitlist-users", email), payload);
      console.log(docRef)
    } catch (e) {
      console.error("Error adding document: ", e);
    }
}

const q = query(collection(db, "waitlist-users"));

export const countWaitlistUser = (func: any) => onSnapshot(q, (querySnapshot) => {
    const waitlistUsers: any = [];
    querySnapshot.forEach((doc) => {
        waitlistUsers.push(doc.data());
    });
    return func(waitlistUsers.length)
});