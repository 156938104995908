import "./styles.scss";

const socials = [
  {
    icon: <i className="lni lni-twitter-filled"></i>,
    url: "https://twitter.com/keepeveryhq/",
  },
  {
    icon: <i className="lni lni-instagram-original"></i>,
    url: "https://www.instagram.com/keepeveryhq/",
  },
  {
    icon: <i className="lni lni-facebook-original"></i>,
    url: "https://fb.me/keepeveryhq"
  },
  {
    icon: <i className="lni lni-linkedin-original"></i>,
    url: "https://www.linkedin.com/company/keepevery/",
  },
];

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <div className="footer-text-p">
              keepevery <span>/ A convenient way to keep long-lasting relationships with every one of your customers.</span>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 address-pane">
            <div className="social-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://keepevery.substack.com"
              >
                Blog
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:hello@keepevery.com"
              >
                Contact
              </a>
              {socials.map(({ icon, url }) => (
                <a href={url} target="_blank" rel="noreferrer">
                  {icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
