import "./styles.scss";
import Logo from "../../assets/images/ke-logo-min.jpg";
import LogoWebP from "../../assets/images/ke-logo.webp";

const Navbar = ({setIsVisible}: any) => {
  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          {/* <picture>
            <source data-aos="fade-in" srcSet={LogoWebP} type="image/webp" />
            <source data-aos="fade-in" srcSet={Logo} type="image/jpeg" />
          </picture> */}
            <img
              src={Logo}
              className="img-fluid"
              alt="keepevery"
            />
        </a>
        <button
          className="btn-main navbar-togglers"
          onClick={() => setIsVisible(true)}
        >
          Join our wait list
        </button>
        {/* <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav ml-auto navbar-centers">
            <button
              className="btn-main"
              onClick={() => setIsVisible(true)}
            >
              Join our wait list
            </button>
          </div>
        </div> */}
      </div>
    </nav>
  );
};

export default Navbar;
