import { Route, Routes } from "react-router";

import HomeLayout from "./layouts/HomeLayout";
import DashboardLayout from "./layouts/DashboardLayout";

import Home4 from "./screens/Home4";
import Dashboard from "./screens/DashboardScreens/Dashboard";
import { useState } from "react";

const App = () => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <Routes>
      <Route element={<HomeLayout setIsVisible={setIsVisible} />}>
        <Route path="/" element={<Home4 isVisible={isVisible} setIsVisible={setIsVisible} />} />
      </Route>
      {/* <Route element={<DashboardLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/users" element={<div>User Account</div>} />
        <Route path="/subscriptions" element={<div>Subscriptions</div>} />
        <Route path="/notifications" element={<div>Notification</div>} />
        <Route path="/settings" element={<div>Settings</div>} />
      </Route> */}
    </Routes>
  );
};

export default App;